img[alt~="roulette-static"] {
  /* position: absolute; */
  /* top: 0px; */
  /* right: 40%; */
  /* left: auto; */
  /* transform: rotate(-40deg); */
  display: none;
}

li.search {
  padding: "10px 0px";
}

li.country {
  text-align: start;
}
