@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");

*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* body {
  background-color: #e63946 !important;
} */
#timepickerboooking .MuiDialogActions-root {
  display: none;
}
